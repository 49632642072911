// Dependencias

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import * as masks from "../../helpers/masks";

// Componentes

import Header from "../Header";
import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Feedback from "../../components/painel/Feedback";
import LoaderAnimation from "../../components/LoaderAnimation";
import Sidebar from "../painel/Sidebar";
import { MobileView, BrowserView } from "react-device-detect";
import Lockr from "lockr";

import { FormUpdateData } from "./support/UpdateData";
import { FormInvoiceDispute } from "./support/InvoiceDispute";
import { FormOmbudsman } from "./support/Ombudsman";
import { FormCancellation } from "./support/Cancellation";
import { FormDevice } from "./support/Device";
import { FormExpirationChange } from "./support/ExpirationChange";
import { FormVehicleChanges } from "./support/VehicleChanges";
import { FormBackupCar } from "./support/BackupCar";
import { FormChangePlan } from "./support/ChangePlan";
import { GetFireWebToCase } from "../../services/SalesForceService";

const options = {
  timeZone: 'America/Sao_Paulo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
};

const Form = (props) => {
  const { register, errors, clearError, setError, setValue } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [isCaseModal, setIsCaseModal] = useState(false);

  const [contractId, setContractId] = useState("");
  const [dataCase, setDataCase] = useState([
    {
      Id: "",
      Status: "New",
      CaseNumber: "",
      Reason: "",
      Fase__c: "",
      CreatedDate: "",
    },
  ]);

  const [phoneContact, setPhoneContact] = useState("");

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  useEffect(() => {
    if (contractId.length > 0) {
      const loadCase = async () => {
        const response = await GetFireWebToCase(contractId);

        const now = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);


        if (response) {
          const filteredResponse = response.filter(
            (item) =>
              (item.Status === "New" || item.Status === "Closed") &&
              (new Date(item.CreatedDate) >= threeMonthsAgo && new Date(item.CreatedDate) <= now) &&
              (item.Reason === "Alteração de Dados Cadastrais" ||
                item.Reason === "Alteração de Plano" ||
                item.Reason === "Alteração de Vencimento" ||
                item.Reason === "Troca de Veículo" ||
                item.Reason === "Carro Reserva" ||
                item.Reason === "Suporte" ||
                /* item.Reason === "Ouvidoria" || */
                item.Reason === "Contestação"),
          );

          setDataCase(filteredResponse);
        }
      };

      loadCase();
    }
  }, [contractId]);

  useEffect(() => {
    (async () => {
      let clientData = await Lockr.get("ClientData");
      if (!clientData.customerSelectedCar.contractInfo.possuiDispositivo) {
        props.handleOptionsChange([
          { value: "", label: "-- Nenhum --" },
          {
            value: "Alteração de Dados Cadastrais",
            label: "Alteração de Dados Cadastrais",
          },
          { value: "Alteração de Plano", label: "Alteração de Plano" },
          {
            value: "Alteração de Vencimento",
            label: "Alteração de Vencimento",
          },
          { value: "Carro Reserva", label: "Carro Reserva" },
          {
            value: "Troca de Veículo/ Correção de Placa",
            label: "Troca de Veículo/ Correção de Placa",
          },
          { value: "Contestação de Fatura", label: "Contestação de Fatura" },
          /* { value: "Ouvidoria", label: "Ouvidoria" }, */
          { value: "Cancelamento", label: "Cancelamento" },
        ])
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[\D]/g, "");
    if (parseInt(value[2]) !== 9 || value.length < 11)
      setError("contactPhone", "invalid", "Número de celular inválido");
    else clearError("contactPhone");

    value = masks.phone(value);
    setValue("contactPhone", value);
    setPhoneContact(value);
    props.handleChange(e);
  };

  const openModalCase = () => {
    setIsCaseModal(true);
  };

  const closeModalCase = () => {
    setIsCaseModal(false);
  };

  const handleOrderArrayDataCase = (a, b) => {
    const faseOrder = {
      "Concluído": 3,
      "Aberto": 1
    };

    const faseA = faseOrder[a.Fase__c] || 2;
    const faseB = faseOrder[b.Fase__c] || 2;

    return faseA - faseB;
  }

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <LoaderAnimation show={isLoading} />
      <h3 className="loovi-header-title loovi-color-2">Solicitações</h3>
      <p className="loovi-color-grey-2">
        Precisa de ajuda? Siga as instruções.
      </p>
      <span className="loovi-spacer"></span>
      {dataCase !== null && dataCase[0] !== undefined && (
        <>
          {dataCase[0].CaseNumber.length > 0 && (
            <>
              <div
                className="cases"
                style={{ cursor: "pointer" }}
                onClick={isCaseModal ? closeModalCase : openModalCase}
              >
                <p className="title-cases">Minhas solicitações</p>
                {isCaseModal ? (
                  <p
                    className="material-icons"
                    style={{
                      marginLeft: "0.2rem",
                    }}
                  >
                    keyboard_arrow_up
                  </p>
                ) : (
                  <p
                    className="material-icons"
                    style={{
                      marginLeft: "0.2rem",
                    }}
                  >
                    keyboard_arrow_down
                  </p>
                )}
              </div>
              {isCaseModal && (
                <div className="array-cases">
                  {dataCase.sort(handleOrderArrayDataCase).map((item, index) => {
                    const date = new Date(item.CreatedDate);
                    const formattedDate = date.toLocaleString('pt-BR', options);

                    const [dateString] = formattedDate.split(', ');
                    const [day, month, year] = dateString.split('/');

                    return (<div className="case-content" key={index}>
                      <p className="name-case">{item.Reason}</p>
                      <div className="status-case">
                        <p className="date-case">{`${day}/${month}/${year.slice(-2)}`}</p>
                        <div
                          className="status-box"
                          style={{
                            background:
                              item.Fase__c === "Aberto"
                                ? "#DEE4FF"
                                : item.Fase__c === "Concluído"
                                  ? "#C9FCE8"
                                  : "#FFF6DA",
                          }}
                        >
                          {item.Fase__c === "Aberto" ? (
                            <p
                              className="material-icons"
                              style={{
                                marginRight: "0.2rem",
                                color: "#515151",
                                transform: "rotate(90deg)",
                              }}
                            >
                              radio_button_unchecked
                            </p>
                          ) : item.Fase__c === "Concluído" ? (
                            <p
                              className="material-icons"
                              style={{
                                marginRight: "0.2rem",
                                color: "#515151",
                              }}
                            >
                              check_circle
                            </p>
                          ) : (
                            <p
                              className="material-icons"
                              style={{
                                marginRight: "0.2rem",
                                color: "#515151",
                                transform: "rotate(120deg)",
                              }}
                            >
                              donut_large
                            </p>
                          )}
                          {item.Fase__c !== "Concluído" &&
                            item.Fase__c !== "Aberto" ? (
                            <p>Em andamento</p>
                          ) : (
                            <p>{item.Fase__c}</p>
                          )}
                        </div>
                      </div>
                    </div>)
                  })}
                </div>
              )}
              {!isCaseModal && <div className="divider"></div>}
            </>
          )}
        </>
      )}
      <span className="loovi-spacer"></span>
      <h4 className="loovi-color-grey-2">Preencha os campos abaixo</h4>
      <>
        <label>
          <span className="text-color-Ivoce">TIPO DE SOLICITAÇÃO</span>
          <select
            name="supportAreaCheck"
            ref={register({ required: true })}
            className="loovi-select"
            value={props.values.supportArea.value}
            onChange={(e) => {
              const val = e.target.value;
              props.handleSelectChange(
                { value: val, label: val },
                "supportArea",
              );
              if (props.values.supportArea.value !== "")
                return clearError("supportAreaCheck");
            }}
          >
            {props.values.supportAreas.map((area, index) => (
              <option key={index} value={area.value}>
                {area.label}
              </option>
            ))}
          </select>
          {errors.supportAreaCheck && (
            <span className="loovi-msg-error">Selecione uma área</span>
          )}
        </label>
        <label>
          <span className="text-color-Ivoce">TELEFONE PARA CONTATO</span>
          <input
            className={
              errors.contactPhone
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
            name="contactPhone"
            onChange={handlePhoneChange}
            ref={register({
              required: true,
              validate: (input) => {
                const value = input.replace(/[\D]/g, "");
                return parseInt(value[2]) === 9 && value.length >= 11;
              },
            })}
            type="text"
            inputMode="numeric"
          />
          {errors.contactPhone && (
            <span className="loovi-msg-error">
              Preencha o campo acima com um telefone válido.
            </span>
          )}
        </label>
        {props.values.supportArea.value === "Alteração de Plano" && (
          <FormChangePlan
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Alteração de Dados Cadastrais" && (
          <FormUpdateData
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Alteração de Vencimento" && (
          <FormExpirationChange
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Suporte Rastreador" && (
          <FormDevice
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Contestação de Fatura" && (
          <FormInvoiceDispute
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Ouvidoria" && (
          <FormOmbudsman
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Cancelamento" && (
          <FormCancellation
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value ===
          "Troca de Veículo/ Correção de Placa" && (
            <FormVehicleChanges
              contactPhone={phoneContact}
              setLoading={setLoading}
              proceed={props.proceed}
            />
          )}
        {props.values.supportArea.value === "Carro Reserva" && (
          <FormBackupCar
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
      </>
    </React.Fragment>
  );
};

export class Support extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const areaURL = params.get('area') ? params.get('area').replace(/-/g, ' ') : "";
    let selectedArea
    if (areaURL === 'Alteracao de Dados Cadastrais') {
      selectedArea = 'Alteração de Dados Cadastrais'
    } else if (areaURL === 'Alteracao de Plano') {
      selectedArea = 'Alteração de Plano'
    }

    if (
      this.props.match.path === "/suporte" ||
      this.props.match.path === "/suporte/:nCtrId"
    ) {
      this.state = {
        step: 1,
        supportAreas: [
          { value: "", label: "-- Nenhum --" },
          {
            value: "Alteração de Dados Cadastrais",
            label: "Alteração de Dados Cadastrais",
          },
          { value: "Alteração de Plano", label: "Alteração de Plano" },
          {
            value: "Alteração de Vencimento",
            label: "Alteração de Vencimento",
          },
          { value: "Carro Reserva", label: "Carro Reserva" },
          { value: "Suporte Rastreador", label: "Suporte Rastreador" },
          {
            value: "Troca de Veículo/ Correção de Placa",
            label: "Troca de Veículo/ Correção de Placa",
          },
          { value: "Contestação de Fatura", label: "Contestação de Fatura" },
          /* { value: "Ouvidoria", label: "Ouvidoria" }, */
          { value: "Cancelamento", label: "Cancelamento" },
        ],
        supportArea: selectedArea
          ? { value: selectedArea, label: selectedArea }
          : { value: "", label: "-- Nenhum --" },
        supportAreaCheck: {},
        supportSubject: "",
        supportDescription: "",
        contactPhone: "",
      };
    } else if (this.props.match.path === "/suporte/:nCtrId/:upSell") {
      if (this.props.match.params.upSell === "0") {
        this.state = {
          step: 1,
          supportAreas: [
            { value: "", label: "-- Nenhum --" },
            {
              value: "Alteração de Dados Cadastrais",
              label: "Alteração de Dados Cadastrais",
            },
            { value: "Alteração de Plano", label: "Alteração de Plano" },
            {
              value: "Alteração de Vencimento",
              label: "Alteração de Vencimento",
            },
            { value: "Carro Reserva", label: "Carro Reserva" },
            {
              value: "Troca de Veículo/ Correção de Placa",
              label: "Troca de Veículo/ Correção de Placa",
            },
            { value: "Suporte Rastreador", label: "Suporte Rastreador" },
            { value: "Contestação de Fatura", label: "Contestação de Fatura" },
            /* { value: "Ouvidoria", label: "Ouvidoria" }, */
            { value: "Cancelamento", label: "Cancelamento" },
          ],
          supportArea: selectedArea
            ? { value: selectedArea, label: selectedArea }
            : { value: "", label: "-- Nenhum --" },
          supportAreaCheck: {},
          supportSubject: "",
          supportDescription: "",
          contactPhone: "",
        };
      } else if (this.props.match.params.upSell === "1") {
        this.state = {
          step: 1,
          supportAreas: [
            { value: "Alteração de Plano", label: "Alteração de Plano" },
          ],
          supportArea: {
            value: "Alteração de Plano",
            label: "Alteração de Plano",
          },
          supportAreaCheck: {},
          supportSubject: "",
          supportDescription: "",
          contactPhone: "",
        };
      }
    }
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ supportArea: selectedOption });
  };
  handleOptionsChange = (options) => {
    this.setState({ supportAreas: options });
  };


  render() {
    const {
      step,
      supportArea,
      supportAreas,
      supportSubject,
      supportDescription,
      contactPhone,
    } = this.state;

    const values = {
      step,
      supportArea,
      supportAreas,
      supportSubject,
      supportDescription,
      contactPhone,
    };

    switch (step) {
      case 2:
        return (
          <Feedback
            backLink
            feedbackTitle={supportArea.value}
            alertTitle="Sua solicitação foi enviada com sucesso!"
            contentTitle="Protocolo 1234567890"
          >
            <p>
              Seu chamado foi aberto com sucesso. Nosso prazo de retorno é de
              até 3 dias úteis.
            </p>
            <p>
              Fique atento à sua caixa de email que o nosso suporte irá retornar
              a sua solicitação.
            </p>
          </Feedback>
        );
      default:
        return (
          <Section>
            <Header>
              <NavBar backButton blueArrow />
            </Header>
            <MobileView>
              <Container>
                <Row>
                  <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <Form
                      nCtrId={this.props.match.params.nCtrId}
                      values={values}
                      handleSelectChange={this.handleSelectChange}
                      handleChange={this.handleChange}
                      proceed={this.proceed}
                      handleOptionsChange={this.handleOptionsChange}
                    />
                  </Col>
                </Row>
              </Container>
            </MobileView>
            <BrowserView>
              <Container fluid>
                <Row>
                  <div className="loovi-fixed-side-column">
                    <Sidebar />
                  </div>
                  <div className="loovi-fluid-column">
                    <Col sm={12} md={6}>
                      <div className="loovi-descktop-padding">
                        <Form
                          nCtrId={this.props.match.params.nCtrId}
                          values={values}
                          handleSelectChange={this.handleSelectChange}
                          handleChange={this.handleChange}
                          proceed={this.proceed}
                          handleOptionsChange={this.handleOptionsChange}
                        />
                      </div>
                    </Col>
                  </div>
                </Row>
              </Container>
            </BrowserView>
          </Section>
        );
    }
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  .address-cep {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .address-cep input {
    width: 96% !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
  }

  .text-color-Ivoce {
    color: #393043 !important;
  }

  .cases {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cases p {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
    color: #515151;
  }

  .array-cases {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .case-content {
    width: 100%;
    padding: 8px 12px;
    border-radius: 12px;
    border: 2px solid #f2f3f8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  .name-case {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #515151;
    width: 45%;
  }

  .status-case {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
  }

  .date-case{
    font-size: 10px;
    line-height: 16px;
    color: rgba(153, 153, 153, 1);
    text-align: end;
    font-weight: 400;

  }

  .status-box {
    min-width: 61px;
    padding: 4px 6px 4px 4px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .status-box p {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #515151;
  }

  .divider {
    width: 100%;
    background: #f2f3f8;
    height: 2px;
    margin-top: 1rem;
  }
`;
