import React, { useEffect, useState } from "react";
import Lockr from "lockr";
import LoaderAnimation from "../LoaderAnimation";
import MagicService from "../../services/MagicService";
import SapService from "../../services/SapService";
import { URLS } from "../../helpers/Request";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { ModalSuccess } from "./Payment/ModalSuccess";

const ENVIRONMENT = process.env.REACT_APP_BASE_ENVIRONMENT;

let loopCount = 0;

const ADYEN_KEYS = {
  staging:
    "pub.v2.8015611161110375.aHR0cDovL2xvY2FsaG9zdDozMDAw.cvRDaexZ4Cl4dGG8s5HLeLUSEO3MhBeGTCUuiE4A0cU",
  development:
    "pub.v2.8015611161110375.aHR0cHM6Ly9zaXRlZGV2Lmxvb3ZpLmNvbS5ici8.RINgO6IgpmIyBg8gEDsnNaZ9C5BC2JRuF6UibBVXllg",
  production:
    "pub.v2.2615796150434824.aHR0cHM6Ly9hcmVhbG9nYWRhLmxvb3ZpLmNvbS5icg.H-ZMjhxhctJBqjdo3B00GAfDDbaZtekjPou573FjZWY",
  local:
    "pub.v2.8015611161110375.aHR0cDovL2xvY2FsaG9zdDozMDAw.cvRDaexZ4Cl4dGG8s5HLeLUSEO3MhBeGTCUuiE4A0cU",
};

const ADYEN_KEY =
  ADYEN_KEYS[window.location.host === "localhost:3000" ? "local" : ENVIRONMENT];
window.ADYEN_KEY = ADYEN_KEY;

export default function FormCreditCard(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCard, setIsValidCard] = useState(false);
  const [card, setCard] = useState(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [cardName, setCardName] = useState("");
  const location = useLocation();
  const isInvoicePage = location.pathname.includes("fatura-em-aberto")
  const [isSucess, setIsSucess] = useState(false)
  console.log(props)

  function fireWebToLead(data, tag) {
    let form;
    console.log(data, tag)


    if (process.env.REACT_APP_BASE_ENVIRONMENT === "production") {
      form = {
        oid: "00D4P0000010vdU",
        retURL: "https://loovi.com.br/",
        first_name: data?.quotation?.quotationFormBusinessPartner?.cardName
          .toString()
          .split(" ")[0],
        last_name: data?.quotation?.quotationFormBusinessPartner?.cardName
          .toString()
          .split(" ")
          .slice(1)
          .join(" "),
        email: data?.quotation?.quotationFormBusinessPartner?.email,
        phone: data?.quotation?.quotationFormBusinessPartner?.phone1,
        lead_source: "Novo",
        "00N4P00000DLrb0": "Site Forms",
        "00NS0000009IiYQ": data?.quotation?.quotationForm?.cmpgnID,
        "00N4P00000FIE0w":
          data?.quotation?.quotationFormAddressDelivery?.zipCode,
        "00N4P00000FIDzZ":
          data?.quotation?.quotationFormAddressDelivery?.street,
        "00N4P00000FIDzo":
          data?.quotation?.quotationFormAddressDelivery?.streetNo,
        "00N6f00000FeUFx":
          data?.quotation?.quotationFormAddressDelivery?.building,
        "00N6f00000FeUFv":
          data?.quotation?.quotationFormAddressDelivery?.ibgeCode,
        "00N4P00000FIE0X": data?.quotation?.quotationFormAddressDelivery?.block,
        "00N4P00000FIE0m": data?.quotation?.quotationFormAddressDelivery?.state,
        "00N4P00000FIE0c": data?.quotation?.quotationFormAddressDelivery?.city,
        "00N6f00000FeUFs": data?.quotation?.quotationFormBusinessPartner?.cpf,
        "00N6f00000FeUFr": data?.quotation?.quotationFormBusinessPartner?.cnpj,
        "00N6f00000FeUG3": "", //inscrição municipal
        "00N6f00000FeUG2": data?.quotation?.quotationFormBusinessPartner?.ie,
        "00N6f00000FeUGF": tag,
        submit: "Submit",
      };
    } else {
      form = {
        oid: "00DS0000003A4nP",
        retURL: "https://loovi.com.br/",
        first_name: data?.quotation?.quotationFormBusinessPartner?.cardName
          .toString()
          .split(" ")[0],
        last_name: data?.quotation?.quotationFormBusinessPartner?.cardName
          .toString()
          .split(" ")
          .slice(1)
          .join(" "),
        email: data?.quotation?.quotationFormBusinessPartner?.email,
        phone: data?.quotation?.quotationFormBusinessPartner?.phone1,
        lead_source: "Novo",
        "00N4P00000DLrb0": "Site Forms",
        "00NS0000009IiYQ": data?.quotation?.quotationForm?.cmpgnID,
        "00N4P00000FIE0w":
          data?.quotation?.quotationFormAddressDelivery?.zipCode,
        "00N4P00000FIDzZ":
          data?.quotation?.quotationFormAddressDelivery?.street,
        "00N4P00000FIDzo":
          data?.quotation?.quotationFormAddressDelivery?.streetNo,
        "00NS0000009IiYT":
          data?.quotation?.quotationFormAddressDelivery?.building,
        "00NS0000009Xg6T":
          data?.quotation?.quotationFormAddressDelivery?.ibgeCode,
        "00N4P00000FIE0X": data?.quotation?.quotationFormAddressDelivery?.block,
        "00N4P00000FIE0m": data?.quotation?.quotationFormAddressDelivery?.state,
        "00N4P00000FIE0c": data?.quotation?.quotationFormAddressDelivery?.city,
        "00NS0000009IiYP": data?.quotation?.quotationFormBusinessPartner?.cpf,
        "00NS0000009IiYO": data?.quotation?.quotationFormBusinessPartner?.cnpj,
        "00NS0000009X4q1": "",
        "00NS0000009X4q0": data?.quotation?.quotationFormBusinessPartner?.ie,
        "00N4P00000FIE11": tag,
        submit: "Submit",
      };
    }

    //console.log(form);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(`${URLS.WEBTOLEAD}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(form),
    })
      .then((res) => {
        console.log("WEB TO LEAD ENVIADO COM SUCESSO!");
        //console.log(res);
      })
      .catch((err) => {
        console.log("ERRO AO ENVIAR WEB TO LEAD.");
        console.log(err);
      });
  }

  function handleOnChange(e) {
    console.log("handleOnChange", e);
    setIsValidCard(e.isValid);
  }

  function handleOnAdditionalDetails(e) {
    console.log("handleOnAdditionalDetails", e);
  }

  function handleOnSubmit(e) {
    console.log("handleOnSubmit", e);
  }

  /* Adyen Aqui*/
  function mountAdyenForm() {
    const checkout = new window.AdyenCheckout({
      locale: "pt_BR",
      evironment: ENVIRONMENT === "production" ? "live" : "test",
      originKey: ADYEN_KEY,
      onChange: handleOnChange,
      onAdditionalDetails: handleOnAdditionalDetails,
      onSubmit: handleOnSubmit,

    });

    setCard(checkout.create("card").mount(".card_form_wrapper__injector"));
  }

  useEffect(() => {
    function startAdyen() {
      // Caso já tenha iterado 40x, aceita
      // que adyen não carrega mais e dispara erro.
      //console.log('loopCount ->', loopCount);
      if (loopCount > 40) {
        alert("Erro de checkout: 05x059203_Adyen_API_not_loaded.");
        return;
      }

      // Caso adyen ainda não exista na janela
      // tenta novamente em 200ms
      if (!window.AdyenCheckout) {
        loopCount += 1;
        setTimeout(startAdyen, 200);
        return;
      }

      // inicia adyen
      mountAdyenForm();
    }

    startAdyen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function handleForm(e) {
    setIsLoading(true);
    e.preventDefault();
    const cardData = card.data.paymentMethod;
    const userData = Lockr.get("Loovi-SAP");


    if (!isValidCard) {
      alert("Erro ao processar pagamento. número do cartão inválido!");
      fireWebToLead(userData, "Compra Falhada");
      setIsLoading(false);
      return;
    }

    if (cardData.encryptedCardNumber === undefined) {
      alert("Erro ao processar pagamento. número do cartão não informado!");
      fireWebToLead(userData, "Compra Falhada");
      setIsLoading(false);
      return;
    } else if (
      cardData.encryptedExpiryMonth === undefined ||
      cardData.encryptedExpiryYear === undefined
    ) {
      alert("Erro ao processar pagamento. mês/ano de expiração não informado");
      fireWebToLead(userData, "Compra Falhada");
      setIsLoading(false);
      return;
    } else if (cardData.encryptedSecurityCode === undefined) {
      alert("Erro ao processar pagamento. CVV não informado");
      fireWebToLead(userData, "Compra Falhada");
      setIsLoading(false);
      return;
    } else if (cardName === "") {
      alert(
        "Erro ao processar pagamento. nome do titular do cartão não informado.",
      );
      fireWebToLead(userData, "Compra Falhada");
      setIsLoading(false);
      return;
    }

    let result;
    let sellerId;

    if (props.isAddCreditCard) {
      const clientData = await Lockr.get("ClientData");

      try {
        // Obtem vendedor
        if (cardName === "") {
          setIsLoading(false);
          return;
        }
        const responseSellers = await SapService.getSellers();

        //console.log(responseSellers)

        if (responseSellers) {
          if (responseSellers.sucess) {
            sellerId = responseSellers.sellers.filter((seller) => {
              return seller.email === "lia@loovi.com.br";
            });
          }
        }
        //console.log(sellerId)
        result = await MagicService.changeCreditCard({
          contract: clientData.customerSelectedCar.contractInfo.nCtrId,
          cardName,
          cardNumber: cardData.encryptedCardNumber,
          cardMonth: cardData.encryptedExpiryMonth,
          cardYear: cardData.encryptedExpiryYear,
          cvv: cardData.encryptedSecurityCode,
          value: 0,
          IdSAP__c: sellerId[0].slpCode,
        });

        if (result) {
          if (result.retorno !== "FALSE" && result.retorno !== "false") {
            setIsLoading(false);
            props.proceed();
            setIsSucess(true)
          } else {
            alert("Oops! Erro trocar o cartão de crédito.");
            setIsLoading(false);
            return;
          }
        } else {
          alert("Oops! Erro trocar o cartão de crédito.");
          setIsLoading(false);
          return;
        }
      } catch (error) {
        alert("Ops, ocorreu um erro ao trocar o cartão de crédito.");
      }
    } else {
      try {

        result = await MagicService.sendPayment(
          userData.quotation.quotationForm.quotID,
          cardName,
          cardData.encryptedCardNumber,
          cardData.encryptedExpiryMonth,
          cardData.encryptedExpiryYear,
          cardData.encryptedSecurityCode,
          userData.quotation.quotationForm.docTotal,
        );

        //console.log(result);

        if (result) {
          if (result.retorno !== "FALSE" && result.retorno !== "false") {
            setIsLoading(false);
            props.proceed();
            setIsSucess(true)
          } else {
            alert(result.mensagemRetorno);
            fireWebToLead(userData, "Compra Falhada");
            setIsLoading(false);
            return;
          }
        } else {
          alert("Ops, ocorreu um erro ao processar pagamento.");
          fireWebToLead(userData, "Compra Falhada");
          setIsLoading(false);
        }
      } catch (error) {
        alert("Ops, ocorreu um erro ao processar pagamento.");
      }
    }
  }


  return (
    <FormStyle className="card_form_wrapper" onSubmit={handleForm}>
      <LoaderAnimation show={isLoading} />
      {!isInvoicePage && <h4>Dados do cartão</h4>}

      <CustomCardDetails className="card_form_wrapper__injector" />

      <InputCardName>
        <label>Nome como no cartão</label>
        <input
          name="customerName"
          className="loovi-field"
          type="text"
          value={cardName}
          onChange={(e) => setCardName(e.target.value.replace(/[0-9]/g, ""))}
          placeholder="Digite o nome que está no cartão"
        />
      </InputCardName>
      {!props.isChangeCreditCard && (
        <label className="loovi-checkbox loovi-checkbox-light">
          <input
            type="checkbox"
            name="customerTermAgree"
            onChange={(e) => setIsTermsChecked(e.target.checked)}
          />
          <span>
            Li e concordo com os{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://contratos-clientes.s3.amazonaws.com/termos-do-contrato-loovi.pdf`}
            >
              Termos do Uso
            </a>{" "}
            e a{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://contratos-clientes.s3.amazonaws.com/politica-de-privacidade-loovi.pdf`}
            >
              Política de Privacidade
            </a>
            .
          </span>
          <span className="checkmark"></span>
        </label>
      )}
      {!props.isChangeCreditCard && (
        <button
          className="loovi-button"
          disabled={isLoading || !isTermsChecked}
        >
          Fazer Pagamento
        </button>
      )}
      {props.isChangeCreditCard && (
        <button className="loovi-button" disabled={isLoading || !isValidCard}>
          {isInvoicePage ? "Confirmar pagamento" : "Trocar Cartão"}
        </button>
      )}

      {isSucess && isInvoicePage && <ModalSuccess text="Pagamento confirmado" onClose={() => { window.location.href = "/pagamentos" }} />}
    </FormStyle>
  );
}

const FormStyle = styled.form`
  padding-inline: 16px;
  margin-top: 32px;

  h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: rgba(81, 81, 81, 1);

  }
`

const CustomCardDetails = styled.div`
  margin-top: 24px;

  label{
    display: flex;
    flex-direction: column;
    align-items: start;

    border: solid 1px rgba(213, 213, 213, 1);
    border-radius: 8px;
    padding: 8px 12px;

    & > span{
      font-size: 10px;
      font-weight: 400;
      line-height: 12.1px;
      text-align: left;
      color: rgba(81, 81, 81, 1);
      margin: 0;

      text-transform: math-auto;
      letter-spacing: normal;
    }

    .adyen-checkout__input-wrapper{
      width: 100%;
    }



    .adyen-checkout__input-wrapper > span{
      border: none;
      padding: 0;
      height: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

    }


    img{
      position: absolute;
      right: 0;
      top: -6px;
      margin: 0;
      transform: none;
      width: 20px;
      height: 20px;
      border-radius: 0;

    }

    iframe{
      height: 20px !important;
      width: 80% !important;
    }
  }
`

const InputCardName = styled.label`
  display: flex;
  justify-content:  space-between;

  border: solid 1px ${({ isActive }) => isActive ? "rgba(81, 81, 81, 1)" : "rgba(213, 213, 213, 1)"};
  border-radius: 8px;
  padding: 8px 12px;
  margin-top: 8px;
    
  align-items: start;
  flex-direction: column;
  width: 100%;

  label{
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: rgba(81, 81, 81, 1);
    margin: 0;

  }

  input{
    border: none;
    width: 100%;
    padding: 0 !important;
    height: 22.5px !important;
    border: none !important;

    ::placeholder{
      color: rgba(161, 164, 171, 1);

      font-size: 15px;
      font-weight: 400;
      line-height: 18.15px;

    }

    :focus{
      border: none !important;
    }
  }
`