// Dependencias

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'
import Lockr from 'lockr';
import menuItens from '../../assets/data/menu-itens.json'
import { useLocation } from 'react-router-dom'

// Components

import { Container, Row, Col } from 'react-bootstrap'
import { BrowserView, MobileView } from "react-device-detect";
import Welcome from '../painel/Welcome'
import LooviSelect from '../styles/LooviSelect'
import { handleChangeCar } from '../../helpers/handleChangeCar'
import { Redirect } from 'react-router'
import LoaderAnimation from '../LoaderAnimation';

// Imagens

import looviLogo from '../../assets/images/loovi-logo.png'
import looviIconImage from '../../assets/images/loovi-logo-icon.png'
import looviIconColorImage from '../../assets/images/loovi-logo-icon-color.png'
import burgerImage from '../../assets/images/burger.png'
import burgerWhiteImage from '../../assets/images/burger-white.png'
import rightArrow from '../../assets/images/arrow-right-white.png'
import leftWhiteArrow from '../../assets/images/arrow-left-white.png'
import leftBlueArrow from '../../assets/images/arrow-left-blue.png'
import logoffImage from '../../assets/images/logoff-mobile.svg'
import { getContratos } from './services.js'
import { authHandler } from '../../helpers/auth.js'

const urlFilter = (item, currentRoute) => {
    if (item.id === 4 && currentRoute === "/pagamentos/cartao") {
        return false
    } else {
        if (currentRoute.includes(item.route)) {
            return true
        }
    }
    return false
}

const MenuItens = () => {
    const currentRoute = useLocation().pathname

    let itens = menuItens.itens.map(item => {
        //Fix the logic to make it more dynamic.

        const isActive = urlFilter(item, currentRoute)

        return (
            <a
                key={item.id}
                className={`menu-item ${isActive ? "isActive" : ""}`}
                href={item.route}>
                {item.label}
            </a>
        )
    })

    return itens
}



export class NavBar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerCars: [],
            customerInfo: {},
            customerSelectedCar: {},
            isLoading: false,
            isOpen: false,
        }


    }

    forceCloseMenu() {
        const overlay = document.querySelector('.bm-overlay');

        if (!overlay) {
            return;
        }

        if (+overlay.style.opacity === 0) {
            return;
        }

        overlay.click();
    }

    async componentDidMount() {
        window.addEventListener("pagehide", () => this.forceCloseMenu());
        window.addEventListener("pageshow", () => this.forceCloseMenu());

        if (authHandler.isLogged()) {
            this.setState({ isLoading: true })
            await getContratos()
            const data = Lockr.get('ClientData')
            this.setState({
                ...data,
                isLoading: false,
            })
        }
    }

    handleSelectChange = selectedOption => {
        const { customerCars, customerInfo } = this.state;
        handleChangeCar({
            customerCars,
            customerInfo,
            selectedOption,
        });
    };

    logout() {
        authHandler.logout()
    }

    goBack = () => {
        if (this.props.closeModal) {
            this.props.closeModal()
            return
        }

        if (this.props.dashboard) {
            window.location.href = "/dashboard"
        } else {
            window.history.back()
        }
    }


    render() {
        const {
            customerCars,
            customerInfo,
            customerSelectedCar,
            isLoading,
        } = this.state;

        if (!authHandler.isLogged()) {
            return <Redirect to="/" />
        }



        return (
            <React.Fragment>
                <LoaderAnimation show={isLoading} />

                <MobileView>
                    <SectionMobile
                        arrowAction={this.props.arrowAction}
                        blueArrow={this.props.blueArrow}
                        backButton={this.props.backButton}
                        burgerWhite={this.props.burgerWhite}
                        titleColor={this.props.titleColor}
                        background={this.props.background}>
                        <Container>
                            <Row>
                                <Col>
                                    <figure><img className="loovi-icon" src={this.props.background && this.props.background !== '#EFF2F7' ? looviIconImage : looviIconColorImage} alt="Loovi" /></figure>
                                </Col>
                            </Row>
                        </Container>
                        <button onClick={this.goBack} className="loovi-back"><img src={this.props.blueArrow ? leftBlueArrow : leftWhiteArrow} alt="Voltar" /></button>
                        <Menu right width="100%" burgerButtonClassName={"burger"} isOpen={this.state.isOpen} >
                            <MenuItens />
                            <a className="menu-logoff" href="/">Sair</a>
                        </Menu>
                    </SectionMobile>
                </MobileView>
                <BrowserView>
                    <SectionDescktop style={{ paddingLeft: this.props.hasBack ? 80 : 0 }}>
                        <Container fluid>
                            <Row className="d-flex justify-align-center align-items-center">
                                <Col sm={12} md={2}><figure><img className="loovi-logo" src={looviLogo} alt="Loovi" /></figure></Col>
                                <Col sm={12} md={4}>
                                    <Welcome customerName={customerInfo.name} inline />
                                </Col>
                                <Col sm={12} md={4}>
                                    <LooviSelect
                                        classNamePrefix="react-select"
                                        value={customerSelectedCar}
                                        onChange={this.handleSelectChange}
                                        options={customerCars} />
                                </Col>
                                <Col sm={12} md={2}>
                                    <a className="menu-logoff" href="/" onClick={this.logout}>Sair</a>
                                </Col>
                            </Row>
                        </Container>
                    </SectionDescktop>
                </BrowserView>
            </React.Fragment>
        )
    }
}

const SectionMobile = styled.section`

    position: relative;
    padding: 30px;

    h5{

        font-family: 'BrownStd-Bold' !important;
        letter-spacing: 1.8px !important;
        font-size: 13px !important;
        text-transform: uppercase;
        text-align: center;
        color: ${props => props.titleColor ? props.titleColor : '#9FA5B3'} !important;
    }

    .loovi-back{

        display: ${props => props.backButton ? 'block' : 'none'};
        position: absolute;
        border: none;
        padding: 0;
        background: transparent;
        top: 31px;
        left: 30px;

        img{

            width: 11px;
            height: auto;
        }
    }

    .burger{

        background-image: url(${props => props.burgerWhite ? burgerWhiteImage : burgerImage});
        background-size: cover;
        width: 30px;
        height: 22px;
        position: absolute;
        top: 30px;
        right: 30px;

        button{

            width: 30px !important;
            height: 24px !important;
        }
    }

    figure{

        text-align: center;
    }

    .loovi-icon{

        width: 63px;    
        height: auto;
        margin: 0 auto;
    }

    .menu-item{

        border: none !important;
        outline: none !important;
        font-family: BrownStd-Regular;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: -0.64px;
        line-height: 24px;
        display: block;
        position: relative;


        ::before{

            content: '';
            display: block;
            width: 9px;
            height: 16px;
            background-image: url(${rightArrow});
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 0;
        }
    }
    

    .bm-menu-wrap{

        background-color: #5A78FF;
        top: 0;
        text-align: left;

        &::before{

            content: '';
            display: block;
            width: 63px;
            height: 26px;
            margin: 22px auto 22px 32px;
            background-image: url(${looviIconImage});
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .bm-menu{
        height: calc(100vh - 70px) !important;
        padding-inline: 32px;
    }

    .bm-item-list{
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 40px;
    }



    .bm-cross-button button:before, .bm-cross-button button:after {

        position: absolute;
        right: 32px;
        top: 15px;
        content: ' ';
        height: 25px;
        width: 2px;
        background-color: #FFFFFF;

    }

    .bm-cross-button button:before {

        transform: rotate(45deg);
    }

    .bm-cross-button button:after {

        transform: rotate(-45deg);
    }

    .menu-logoff{
        color: #FFFFFF;
        padding: 0 0 48px 30px;
        font-size: 16px;
        background-image: url(${logoffImage});
        background-repeat: no-repeat;
        background-size: 23px;
        background-position: start left;
        outline: none;

        margin-top: 114px;
    }

    .isActive{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 8px;

        &::after{
            content: '';
            display: block;
            width: 4px;
            height: 22px;
            background-color: white;
            border-radius: 12px;
        }
    }
`

const SectionDescktop = styled.section`
    background: #5A78FF;
    padding: 30px 0 30px;

    .loovi-logo{

        max-width: 100px;
        height: auto;
    }

    .menu-logoff{

        color: #FFFFFF;
        padding: 4px 0 4px 30px;
        font-size: 16px;
        background-image: url(${logoffImage});
        background-repeat: no-repeat;
        background-size: 23px;
        background-position: center left;
    }
`

export default NavBar
